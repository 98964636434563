import * as React from 'react';
import { useEffect } from 'react';
import loadable from '@loadable/component';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { sectionList, FormTypes } from '../../constants/queryFragments';
import { graphql, navigate } from 'gatsby';
import Cookies from 'universal-cookie';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import GlobalStyles from '../../styles/globalStyles';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import LanguageModal from '../../components/languageModal/languageModal';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import i18next from '../../i18next';
import {
  addTrailingSlash,
  isValidURLLanguage,
  isValidURLRegion,
  validNodeLocale,
} from '../../utils';
import ogLogo from '../../images/og-logo.jpg';

const CommonPageTemplate = ({ data, location }) => {
  const cookies = new Cookies();
  const [loaded, setLoaded] = React.useState(false);

  const date = new Date();
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  React.useEffect(() => {
    setLoaded(true);
    if (!window.location.hash) {
      window.scrollTo(0, 0);
    }
  }, []);
  const queryParams = new URLSearchParams(location?.search);
  const regionQueryParams = queryParams.get('region')?.toUpperCase();

  typeof window !== 'undefined' &&
    regionQueryParams &&
    window?.history?.replaceState({}, document?.title, location?.pathname);

  const updateAndSetRegionCookie = () => {
    if (
      isValidURLRegion(regionQueryParams) &&
      cookies.get('region')?.toUpperCase() !== regionQueryParams
    ) {
      cookies.set('region', regionQueryParams?.toLowerCase(), {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return regionQueryParams;
    }
  };

  const updateAndSetLanguageCookie = (language) => {
    if (language && cookies.get('language') !== language) {
      cookies.set('language', language, {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return language;
    }
  };

  const selectedRegion =
    isValidURLRegion(regionQueryParams) &&
    cookies.get('region')?.toUpperCase() !== regionQueryParams
      ? updateAndSetRegionCookie()
      : cookies.get('region')?.toUpperCase()
      ? cookies.get('region')?.toUpperCase()
      : 'NB';

  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);

  const selectedLanguage =
    isValidURLLanguage(urlLanguage) &&
    validNodeLocale(urlLanguage) &&
    cookies.get('language') !== validNodeLocale(urlLanguage)
      ? updateAndSetLanguageCookie(validNodeLocale(urlLanguage))
      : cookies.get('language')
      ? cookies.get('language')
      : 'en-US';

  const regionLanguageFilteredData =
    data?.allContentfulUltramarCommercial?.edges?.find(
      (edge) => edge?.node?.node_locale === selectedLanguage
    );

  const englishSlug = data?.allContentfulUltramarCommercial?.edges?.find(
    (edge) => edge?.node?.node_locale === 'en-US'
  )?.node?.slug;

  const frenchSlug = data?.allContentfulUltramarCommercial?.edges?.find(
    (edge) => edge?.node?.node_locale === 'fr-CA'
  )?.node?.slug;

  const sectionsArray = regionLanguageFilteredData?.node?.sections?.filter(
    (region) => region.abbreviation === selectedRegion
  )[0]?.sections;

  useEffect(() => {
    !sectionsArray &&
      isValidURLLanguage(urlLanguage) &&
      (urlLanguage === 'fr'
        ? navigate('/fr/contenu-indisponible/')
        : navigate('/en/content-unavailable/'));
  }, [sectionsArray]);

  return !loaded ? (
    <>
      <Helmet>
        <title>
          {regionLanguageFilteredData?.node?.title + ' | Ultramar' ??
            'Energy Solutions for Residential and Businesses | Ultramar'}
        </title>
        {englishSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + englishSlug)}
            hreflang="en-ca"
          ></link>
        )}
        {frenchSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + frenchSlug)}
            hreflang="fr-ca"
          ></link>
        )}
        {regionLanguageFilteredData?.node?.metadata && (
          <meta
            name="description"
            content={regionLanguageFilteredData?.node?.metadata}
          ></meta>
        )}
        {regionLanguageFilteredData?.node?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
        <meta property="og:image" content={ogLogo}></meta>
        <meta property="twitter:image" content={ogLogo}></meta>
      </Helmet>
      <GlobalStyles />
      <Header englishSlug={englishSlug} frenchSlug={frenchSlug} />
      <>
        {sectionsArray &&
          sectionsArray?.map((section, index) => {
            if (
              section?.type &&
              sectionList?.includes(section?.type) &&
              !FormTypes?.includes(section?.type)
            ) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section?.type}/${section?.type}`)
              );
              return (
                <SectionComponent
                  key={index}
                  sectionData={section}
                  location={location}
                />
              );
            }
            return <></>;
          })}
      </>
      <BackToTopScroll />
      <Footer />
    </>
  ) : (
    <>
      <I18nextProvider i18n={i18next}>
        <Helmet>
          <title>
            {regionLanguageFilteredData?.node?.title
              ? regionLanguageFilteredData?.node?.title + ' | Ultramar'
              : 'Energy Solutions for Residential and Businesses | Ultramar'}
          </title>
          {englishSlug && (
            <link
              rel="alternate"
              href={addTrailingSlash(
                process.env.GATSBY_DOMAIN_URL + englishSlug
              )}
              hreflang="en-ca"
            ></link>
          )}
          {frenchSlug && (
            <link
              rel="alternate"
              href={addTrailingSlash(
                process.env.GATSBY_DOMAIN_URL + frenchSlug
              )}
              hreflang="fr-ca"
            ></link>
          )}
          {regionLanguageFilteredData?.node?.metadata && (
            <meta
              name="description"
              content={regionLanguageFilteredData?.node?.metadata}
            ></meta>
          )}
          {regionLanguageFilteredData?.node?.hasIndex === false && (
            <meta name="robots" content="noindex" />
          )}
          <meta property="og:image" content={ogLogo}></meta>
          <meta property="twitter:image" content={ogLogo}></meta>
        </Helmet>
        <GlobalStyles />
        <LanguageModal />
        <Header englishSlug={englishSlug} frenchSlug={frenchSlug} />
        <>
          {sectionsArray &&
            sectionsArray?.map((section, index) => {
              if (section?.type && sectionList?.includes(section?.type)) {
                const SectionComponent = loadable(() =>
                  import(`../../components/${section?.type}/${section?.type}`)
                );
                return (
                  <SectionComponent
                    key={index}
                    sectionData={section}
                    location={location}
                  />
                );
              }
              return <></>;
            })}
        </>
        <BackToTopScroll />
        <Footer />
      </I18nextProvider>
    </>
  );
};

export default CommonPageTemplate;

export const pageQuery = graphql`
  query allCommonPagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulContentList: Boolean!
    $contentfulTextWithImage: Boolean!
    $contentfulFaqs: Boolean!
    $contentfulListImagesWithPath: Boolean!
    $contentfulImageWithPath: Boolean!
  ) {
    allContentfulUltramarCommercial(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          metadata
          hasIndex
          title
          node_locale
          sections {
            ... on ContentfulRegions {
              id
              title
              abbreviation
              sections {
                ...TextWithImage @include(if: $contentfulTextWithImage)
                ...PageContent @include(if: $contentfulContent)
                ...ContentList @include(if: $contentfulContentList)
                ...FAQContent @include(if: $contentfulFaqs)
                ...ImagesWithPathList
                  @include(if: $contentfulListImagesWithPath)
                ...ImageWithPath @include(if: $contentfulImageWithPath)
              }
            }
          }
        }
      }
    }
  }
`;
